<template>

  <PopperModal :popper-id="modalId">
    <template #popperHeader>Event Class</template>
    <template #popperBody>
      <div class="my-3 px-5 text-left">
        <h5 style="font-size: 1.25rem">SMS-H-01
          3.  CLASSIFICATION OF EMERGENCY SITUATION</h5>

        <p style="font-size: 8.65pt">3.1  The Company shall classify all Emergencies into three Classes namely, A, B, C as follows:</p>
            <p style="font-size: 14px; font-style: italic">3.1  The Company shall classify all Emergencies into three Classes namely; A, B, C as follows:</p>

            <h6 class="font-weight-bold">Class A:</h6>
            <ul>
              <li>A Major Accident with a Great Social Impact, Involving Huge Damage to us or Affecting a large number of Third Parties.</li>
              <li>A Major Accident with Widely Covered by Mass Media and requires a Prompt Response.</li>
              <li>A Major Accident which requires response measures by the Entire Company.</li>
            </ul>

        <p>Requires formation of Emergency Response Team (ERT).</p>


            <p>(Examples of Emergencies, but not limited to:)</p>
            <ul>
              <li>MARINE POLLUTION INCIDENTS BY THE VESSEL</li>
              <li>SERIOUS MARINE CASUALTIES (FIRE, COLLISION, STRANDING/GROUNDING, FLOODING/SINKING, SERIOUS TERRORISM SUCH AS HIJACKING AND PIRACY)</li>
              <li>OTHER CASUALTIES WHICH MAY HAVE A SERIOUS DAMAGE ON THE LIFE, VESSEL, CARGO AND ENVIRONMENT, INVOLVING LARGE NUMBER OF THIRD PARTIES, HAVING SIGNIFICANT SOCIAL IMPACT AND WIDELY COVERED BY MASS MEDIA</li>
            </ul>


            <h6 class="font-weight-bold">Class B:</h6>
            <p>
              Accidents with Medium Impact, Medium Social Impact and Coverage by the Media and not having Potential of Expanding. <br>
              Requires formation of ERT.
            </p>

            <p>(Examples, but not limited to):</p>
            <ul>
              <li>Accident like Loss of Propulsion which requires assistance of an ocean tug resulting from main engine or Auxiliaries Failure, etc.</li>
              <li>Collision with another vessel (Serious Hull Damage without Oil Spills)</li>
              <li>Minor Oil Spill Incident (As result of other than Collision and Grounding)</li>
              <li>Major Cargo Damage (Including Fire and Hull Damage but excluding Oil Spill)</li>
              <li>Cyber-attack resulting in Loss of Communication, or seriously Damaging Navigable Capabilities of the Vessel.</li>
            </ul>

        <h6 class="font-weight-bold">Class C:</h6>
        <p>
          Accidents other than Category A and B. <br>
          Does not require formation of ERT, can be handled by the Person in charge.
        </p>
      </div>
    </template>
    <template #popperFooter>
    </template>
  </PopperModal>

</template>

<script>


import {defineComponent} from "vue";
import PopperModal from "@/components/elements/PopperModal.vue";

export default defineComponent({
  name: 'EventClassHelpMenuModal',
  components: {PopperModal},
  props: {
    modalId: String
  }
})
</script>

<style scoped>
.modal-header {
  display: block !important;
}

#dismissBtn {
  position: absolute;
  top: 13px;
  right: 16px;
}
</style>
